body {
  background-size: cover;
  background-image: url(https://my.id.myob.com/images/bg_f9f9f9.png);
  display: flex;
  text-align: center;
  flex-direction: column;
  padding: 10vh 0;
  font-family: ulp-font, -apple-system, BlinkMacSystemFont, Roboto, Helvetica, sans-serif;
}

body::before {
  content: '';
  background: linear-gradient(135deg,#5f00a8 35%,#ed005f 100%);
  display: block;
  height: 3px;
  left: 0;
  margin-bottom: 37px;
  position: absolute;
  top: 0;
  width: 100%;
}

#app {
  display: flex;
  font-family: ulp-font, -apple-system, BlinkMacSystemFont, Roboto, Helvetica, sans-serif;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

