.main {
  margin: 0 auto;
  display: block;
}

#prompt-logo-center {
  height: 40px;
}

.ulp-alternate-action a {
  border-radius: 3px;
  border-radius: var(--border-radius-component);
  padding: 4px;
  font-weight: 700;
  font-weight: var(--font-bold-weight);
}

.link-colour {
  color: var(--base-focus-color);
}
